<template>
  <div class="lvl-test__wrapper">
    <p class="_p-18 _p--300 _p--center">Выберите правильный вариант перевода</p>
    <div
      class="lvl-test__counter _p-24 _p--500 mx-auto mt-12"
      data-aos="zoom-in"
      data-aos-delay="50"
    >
      <span>{{ step + 1 }}</span
      >/{{ tests.length }}
    </div>

    <div class="lvl-test__form pt-20">
      <!-- <transition-group name="question-change" mode="out-in"> -->
      <div
        class="lvl-test__question"
        v-for="(question, index) in tests"
        :key="question.MIGX_id"
        v-show="step === index"
      >
        <h6 class="_p-24 _p--500 _p--center">
          {{ question.quest }}
        </h6>

        <div class="lvl-test__variants pt-40 mx-auto pb-15">
          <div
            v-for="answer in question.answers"
            :key="answer.MIGX_id + question.MIGX_id"
            class="lvl-test__answer"
          >
            <test-answer
              :answer="answer"
              :name="'question' + question.MIGX_id"
              :selectedValue="question.value"
              @changeVal="question.value = $event"
            ></test-answer>
          </div>
        </div>
      </div>
      <!-- </transition-group> -->
    </div>
  </div>
</template>

<script>
import TestAnswer from "./TestAnswer.vue";

export default {
  components: { TestAnswer },
  props: {
    tests: {
      type: Array,
    },
    step: {
      type: Number,
    },
  },
};
</script>

<style lang="scss">
.question-change-enter-active,
.question-change-leave-active {
  transition: 500ms;

  .lvl-test__question {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}

.question-change-enter-from {
  // transform: translateX(260px) scale(0.6);
  // .lvl-test__variants {
  opacity: 0;
  // .lvl-test__question {
  //   min-width: 0;
  //   max-width: 0;
  //   width: 0;
  // }
  // }
}
.question-change-leave-to {
  // transform: translateX(-260px) scale(0.6);
  // .lvl-test__variants {
  opacity: 0;
  // .lvl-test__question {
  //   min-width: 0;
  //   max-width: 0;
  //   width: 0;
  // }
  // }
}
</style>
