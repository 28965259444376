<template>
  <div class="lvl-test-input">
    <div class="lvl-test-input__box">
      <input
        type="radio"
        :name="name"
        :value="answer.MIGX_id"
        @change="$emit('changeVal', answer.MIGX_id)"
        :checked="selectedValue === answer.MIGX_id"
        :class="[
          'lvl-test-input__input',
          { _checked: selectedValue === answer.MIGX_id },
        ]"
      />
      <span class="lvl-test-input__circle"></span>
      <label for="" class="lvl-test-input__label _p--500"
        >{{ answer.title }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: ["answer", "selectedValue", "name"],
};
</script>
