<template>
  <div class="lvl-test__btns-row pt-40">
    <div
      class="lvl-test__btn-prev m-btn"
      v-show="step > 0 && !isShowResalt"
      @click="prevQuestion()"
    >
      <span class="m-btn__title">Предыдущий</span>
    </div>
    <div
      :class="['lvl-test__btn-next m-btn', { _disable: !isAnsweredStep }]"
      v-show="step < tests.length - 1 && !isShowResalt"
      @click="nextQuestion()"
    >
      <span class="m-btn__title">Следующий</span>
    </div>
    <div
      :class="['lvl-test__btn-next m-btn', { _disable: !isAnsweredStep }]"
      v-show="step === tests.length - 1 && !isShowResalt"
      @click="completeTest()"
    >
      <span class="m-btn__title">Завершить тест</span>
    </div>
    <div
      class="lvl-test__btn-prev m-btn"
      v-show="isShowResalt"
      @click="$emit('takeAgain')"
    >
      <span class="m-btn__title">Пройти еще раз</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["step", "tests", "isShowResalt"],
  methods: {
    prevQuestion() {
      if (this.step !== 0) this.$emit("changeStep", this.step - 1);
    },
    nextQuestion() {
      if (this.step !== this.tests.length && this.isAnsweredStep)
        this.$emit("changeStep", this.step + 1);
    },
    completeTest() {
      if (this.isAnsweredStep) this.$emit("completeTest");
    },
  },
  computed: {
    isAnsweredStep() {
      if (this.tests[this.step].value === "") return false;
      else return true;
    },
  },
};
</script>
