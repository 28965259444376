<template>
  <!-- <form class="tmp" @submit.prevent=""> -->
    <test></test>
  <!-- </form> -->
</template>

<script>
import Test from "./components/Test.vue";

export default {
  name: "App",
  components: { Test },
};
</script>

<style lang="scss">
</style>
