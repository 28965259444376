<template>
  <div class="lvl-test__answ-wrap mx-auto">
    <h4 class="_h4 _p--600 _p--center">Ваш результат тестирования:</h4>
    <div
      class="lvl-test__resulat-val _p--purple _p--500 pt-20 _p--center mb-20"
    >
      {{ persent }}%
    </div>
    <div class="lvl-test__result-card _p--300 pt-40 pb-40">
      ¡Lo siento!<br />¡Por lo visto algo no ha quedado claro!<br />¡Tendrás que
      repetir este curso! <br /><br />Для стандартного уровня Elemental A1
      должно быть не менее 80% правильных ответов. Ваш результат пока что ниже,
      но не отчаивайтесь - все можно легко исправить.<br /><br />
      Запишитесь на один из моих курсов и я гарантированно научу вас говорить,
      читать и писать на испанском языке.
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tests: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    persent() {
      let persent = 0;
      const worth = 100 / this.tests.length;
      this.tests.forEach(({ value, correct }) => {
        console.log(value + "  " + correct);
        persent += value === correct ? worth : 0;
      });

      return persent;
    },
  },
};
</script>
