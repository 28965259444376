<template>
  <div class="container" v-if="questions">
    <div class="lvl-test pt-20">
      <test-questions-list
        v-show="!isShowResalt"
        :step="step"
        :tests="questions"
      ></test-questions-list>

      <test-resalt v-show="isShowResalt" :tests="questions"></test-resalt>

      <test-btns-row
        :step="step"
        :isShowResalt="isShowResalt"
        :tests="questions"
        @changeStep="step = $event"
        @completeTest="isShowResalt = true"
        @takeAgain="resetProgress()"
      ></test-btns-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TestBtnsRow from "./TestBtnsRow.vue";
import TestQuestionsList from "./TestQuestionsList.vue";
import TestResalt from "./TestResalt.vue";
export default {
  components: { TestQuestionsList, TestBtnsRow, TestResalt },
  name: "TestPage",
  data() {
    return {
      tests: [
        {
          title: "Эта программа очень интересная",
          value: null,
          answers: [
            {
              value: "1",
              title: "Este programa es muy interesante",
            },
            {
              value: "2",
              title: "Esta programa es muy interesante 2",
            },
            {
              value: "3",
              title: "Este programa es muy interesante 3",
            },
          ],
        },
        {
          title: "Эта программа очень интересная 1",
          value: null,
          answers: [
            {
              value: "1",
              title: "Este programa es muy interesante",
            },
            {
              value: "2",
              title: "Esta programa es muy interesante 2",
            },
            {
              value: "3",
              title: "Este programa es muy interesante 3",
            },
          ],
        },
        {
          title: "Эта программа очень интересная 1",
          value: null,
          answers: [
            {
              value: "1",
              title: "Este programa es muy interesante",
            },
            {
              value: "2",
              title: "Esta programa es muy interesante 2",
            },
            {
              value: "3",
              title: "Este programa es muy interesante 3",
            },
          ],
        },
      ],
      step: 0,
      isShowResalt: false,
      questions: null,
    };
  },
  watch: {
    questions: {
      handler(val) {
        // console.log("handler");
        console.log(val);
      },
      deep: true,
    },
  },
  methods: {
    resetProgress() {
      console.log("Reset Progress");
      this.step = 0;
      this.isShowResalt = false;
      this.questions.forEach((el) => (el.value = ""));
    },
    sendTests() {},
    async getTestsData() {
      const id = document.querySelector("#app-test").dataset.id;
      await axios
      // https://planeta-espanol.cms.demopro.tk/test-respond?id=${id}
        .get(`/test-respond?id=${id}`)
        .then((response) => {
          if (response.data) {
            if (response.data.title)
              document.querySelector("head").querySelector("title").innerText =
                response.data.title;

            if (response.data.questions) {
              this.questions = response.data.questions;
            }
          }
        });
    },
  },
  created() {
    /* eslint-disable */
    this.getTestsData();
    var testModal;
    window.testModal = { resetProgress: this.resetProgress, _this: this };
  },
};
</script>
